export default [
  {
    title: "Ana Sayfa",
    to: { name: "root" },
    icon: { icon: "tabler-smart-home" },
    yetki: { k: [] },
  },
  {
    title: "Kullanıcılar",
    to: { name: "kullanicilar" },
    icon: { icon: "tabler-users-group" },
    yetki: { k: [], a: true },
  },
  {
    title: "İş Kartı",
    to: { name: "is_karti" },
    icon: { icon: "tabler-credit-card-pay" },
    yetki: { k: [], a: true },
  },
  {
    title: "Cari",
    to: { name: "cari" },
    icon: { icon: "mdi--business-card" },
    yetki: { k: [], a: true },
  },
  {
    title: "Araç",
    to: { name: "arac" },
    icon: { icon: "gravity-ui--car" },
    yetki: { k: [], a: true },
  },
  {
    title: "İş Tanıtım",
    to: { name: "is_tanitim" },
    icon: { icon: "fluent--credit-card-toolbox-20-filled" },
    yetki: { k: [], a: true },
  },
  {
    title: "Parça Tanıtım",
    to: { name: "parca_tanitim" },
    icon: { icon: "fluent-mdl2--product-variant" },
    yetki: { k: [], a: true },
  },
  {
    title: "Parametre",
    to: { name: "parametre" },
    icon: { icon: "tabler-settings" },
    yetki: { k: [], a: true },
  },
];
