export const routesTanitim = [
  {
    path: "/is_tanitim",
    name: "is_tanitim",
    component: () => import("@/views/tanitim/is/index.vue"),
    meta: {
      public: false,
      title: "İş Tanıtım",
      yetki: { k: [], a: true },
    },
  },
  {
    path: "/parca_tanitim",
    name: "parca_tanitim",
    component: () => import("@/views/tanitim/parca/index.vue"),
    meta: {
      public: false,
      title: "Parça Tanıtım",
      yetki: { k: [], a: true },
    },
  },
  {
    path: "/arac",
    name: "arac",
    component: () => import("@/views/tanitim/arac/index.vue"),
    meta: {
      public: false,
      title: "Araç",
      yetki: { k: [], a: true },
    },
  },
  {
    path: "/cari",
    name: "cari",
    component: () => import("@/views/tanitim/cari/index.vue"),
    meta: {
      public: false,
      title: "Cari",
      yetki: { k: [], a: true },
    },
  },
  {
    path: "/is_karti",
    name: "is_karti",
    component: () => import("@/views/is_karti/index.vue"),
    meta: {
      public: false,
      title: "İş Kabul Kartı",
      yetki: { k: [], a: true },
    },
  },
  {
    path: "/is_karti_add",
    name: "is_karti_add",
    component: () => import("@/views/is_karti/add.vue"),
    meta: {
      public: false,
      title: "İş Kabul Kartı Yeni",
      yetki: { k: [], a: true },
    },
  },
  {
    path: "/is_karti/:id",
    name: "is_karti_kabul",
    component: () => import("@/views/is_karti/update.vue"),
    meta: {
      public: false,
      title: "İş Kabul Kartı Güncelle",
      yetki: { k: [], a: true },
    },
  },
];
