export const routesUser = [
  {
    path: "/profil",
    name: "profil",
    component: () => import("@/views/user/profil/index.vue"),
    meta: { public: false, title: "Profil" },
  },
  {
    path: "/kullanicilar",
    name: "kullanicilar",
    component: () => import("@/views/kullanicilar/index.vue"),
    meta: {
      public: false,
      title: "Kullanıcılar",
      yetki: { k: [], a: true },
    },
  },
  {
    path: "/kullanicilar/:userId",
    name: "kullanicilar_profil",
    component: () => import("@/views/kullanicilar/profil/index.vue"),
    meta: {
      public: false,
      title: "Kullanıcı Profil",
      yetki: { k: [], a: true },
    },
  },
];
