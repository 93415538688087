export const routesAuth = [
  {
    path: "/login",
    name: "login",
    meta: { layout: "blank", public: true },
    component: () => import("@/views/auth/login.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    meta: { layout: "blank", public: true },
    component: () => import("@/views/auth/logout.vue"),
  },
  {
    path: "/register",
    name: "register",
    meta: { layout: "blank", public: true },
    component: () => import("@/views/auth/register.vue"),
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    meta: { layout: "blank", public: true },
    component: () => import("@/views/auth/forgot-password.vue"),
  },
  {
    path: "/reset-password/:token",
    name: "reset-password",
    meta: { layout: "blank", public: true },
    // props: (route) => ({ token: route.params.token, email: route.query.email }),
    component: () => import("@/views/auth/reset-password.vue"),
  },

  {
    path: "/not-authorized",
    name: "not-authorized",
    meta: { layout: "blank", public: true },
    component: () => import("@/views/auth/not-authorized.vue"),
  },
];
