import { useAuthStore } from "@/aygulyazilim/store/useAuth";
import { useHelpersStore } from "@/aygulyazilim/store/useHelpers";

export const setupGuards = (router) => {
  // 👉 router.beforeEach
  // Docs: https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards
  router.beforeEach(async (to) => {
    const authStore = useAuthStore();
    const store = useHelpersStore();
    await store.fetchConfig();

    document.title = to.meta?.title
      ? to.meta?.title + " | " + store.config.firma_adi
      : store.config.firma_adi;

    /*
     * If it's a public route, continue navigation. This kind of pages are allowed to visited by login & non-login users. Basically, without any restrictions.
     * Examples of public routes are, 404, under maintenance, etc.
     */
    if (to.meta.public) return;
    if (authStore.user.uuid.length == 0) await authStore.ftechUser();

    /**
     * Check if user is logged in by checking if token & user data exists in local storage
     * Feel free to update this logic to suit your needs
     */
    // const isLoggedIn = !!(useCookie('userData').value && useCookie('accessToken').value)

    /*
          If user is logged in and is trying to access login like page, redirect to home
          else allow visiting the page
          (WARN: Don't allow executing further by return statement because next code will check for permissions)
         */

    // eslint-disable-next-line sonarjs/no-collapsible-if
    if (to.meta.yetki != undefined && to.matched.length) {
      if (!store.yetkiKontrol(to.meta.yetki)) {
        return authStore.isLoggedIn
          ? { name: "not-authorized" }
          : { name: "login" };
      }
    }

    // if (to.meta.unauthenticatedOnly) {
    //   if (authStore.isLoggedIn)
    //     return '/'
    //   else
    //     return undefined
    // }

    // if (!canNavigate(to) && to.matched.length) {
    //   /* eslint-disable indent */
    //         return authStore.isLoggedIn
    //             ? { name: 'not-authorized' }
    //             : {
    //                 name: 'login',
    //                 query: {
    //                     ...to.query,
    //                     to: to.fullPath !== '/' ? to.path : undefined,
    //                 },
    //             }
    //         /* eslint-enable indent */
    // }

    return;
  });
};
