import { useHelpersStore } from "@/aygulyazilim/store/useHelpers";
import { router } from "@/plugins/1.router";
import { getI18n } from "@/plugins/i18n";
import axios from "axios";
const i18n = getI18n();
const locale = i18n.global.locale.value;
const { t } = i18n.global;

const api = axios.create({
  baseURL: import.meta.env.VITE_API_ENDPOINT,
  timeout: 10000, // Set a timeout value
  withCredentials: true,
  withXSRFToken: true,
  headers: {
    "Content-Type": "application/json",
    charset: "UTF-8",
    lang: locale,
  },
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(
      import.meta.env.VITE_APP_TITLE + "-token"
    );
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // useHelpersStore().$reset();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      switch (error.response?.status) {
        case 401:
          localStorage.removeItem(import.meta.env.VITE_APP_TITLE + "-token");
          router.push({ name: "login" });
          break;
        case 403:
        case 404:
        case 500:
          useHelpersStore().toast("error", t(error.response.data.message));
          break;
        case 422:
          if (error.response.data.errors) {
            for (var key in error.response.data.errors) {
              if (error.response.data.errors.hasOwnProperty(key)) {
                useHelpersStore().toast(
                  "error",
                  t(error.response.data.errors[key][0])
                );
              }
            }
          } else {
            useHelpersStore().toast("error", t(error.response.data.message));
          }
          break;
        default:
          useHelpersStore().toast("error", t(error.response.data.message));

          return Promise.reject(error);
      }
    } else if (error.request) {
      // The request was made, but no response was received
      // console.log("No response received:", error.request);
      useHelpersStore().toast("error", t("Servis Hatası..."));
      if (process.env.NODE_ENV === "development") {
        // console.log("Bu geliştirme modunda çalışıyor!");
      } else if (process.env.NODE_ENV === "production") {
        localStorage.removeItem(import.meta.env.VITE_APP_TITLE + "-token");
        // router.push({ name: "login" });
      }
    } else {
      // Something happened in setting up the request that triggered an error
      // console.log("Axios error:", error.message);
      useHelpersStore().toast("error", t("Beklenmeyen bir hata oluştu."));
    }

    // Optionally return a Promise rejection to handle the error in other parts of the app
    // Hata durumlarında Promise.reject ile hatayı döndür.
    return Promise.reject(error);
  }
);

export default api;
