import api from "@/aygulyazilim/libs/axios";
import navMenuItems from "@/navigation/vertical";
import moment from "moment";
import { defineStore } from "pinia";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useAuthStore } from "./useAuth";

export const useHelpersStore = defineStore("helpers", {
  state: () => {
    const formatTarih = (date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${day}.${month}.${year}`;
    };
    return {
      formatTarih,
      message: null,
      errors: {},
      config: ref({
        firma_adi: "",
        varsayilan_il: 1,
        resim_upload_max: 7,
        video_upload_max: 100,
        nakitbilgi: "",
        whatsapp_link: "",
        havalebilgi: "",
        gizlilik_politikasi: "",
      }),
      ilList: [],
      items: [],
    };
  },
  getters: {
    getDeger: (state) => {
      return (value, tip = 0) => {
        const item = state.items.find((item) => item.name === value);
        if (tip == 1) return item?.deger == 1 ? true : false;
        return item ? item.deger : "";
      };
    },
    navMenu() {
      const tempData = [];
      for (let index = 0; index < navMenuItems.length; index++) {
        if (navMenuItems[index].yetki !== undefined) {
          if (this.yetkiKontrol(navMenuItems[index].yetki)) {
            if (navMenuItems[index].children !== undefined) {
              const child = [];
              for (let i = 0; i < navMenuItems[index].children.length; i++) {
                if (this.yetkiKontrol(navMenuItems[index].children[i].yetki)) {
                  // navMenuItems[index].children.splice(i, 0);
                  child.push(navMenuItems[index].children[i]);
                }
              }
              navMenuItems[index].children = child;
            }
            tempData.push(navMenuItems[index]);
          }
        } else {
          tempData.push(navMenuItems[index]);
        }
      }

      return tempData;
    },
    yetkiKontrol() {
      return ({ k = [], a = false, p = false }) => {
        if ((k.length == 0) & (a == false) & (p == false)) return true;
        if (p && useAuthStore().user.programci) return true;
        if (a && (useAuthStore().user.programci || useAuthStore().user.admin))
          return true;

        for (let i = 0; i < k.length; i++) {
          if (useAuthStore().user.programci || useAuthStore().user.admin)
            return true;
          const s = useAuthStore().user.yetkiler.filter((x) => x.kod === k[i]);
          if (s.length > 0) return true;
        }

        return false;
      };
    },
  },
  actions: {
    async fetchConfig() {
      try {
        this.items = [];
        const res = await api.get("config");
        if (res != undefined) {
          this.items = res?.data;
          this.config.firma_adi = this.getDeger("firma_adi");

          // this.config.varsayilan_il = this.getDeger("varsayilan_il");
          // this.config.resim_upload_max = this.getDeger("resim_upload_max");
          // this.config.video_upload_max = this.getDeger("video_upload_max");
          // this.config.nakitbilgi = this.getDeger("nakitbilgi");
          // this.config.whatsapp_link = this.getDeger("whatsapp_link");
          // this.config.havalebilgi = this.getDeger("havalebilgi");
          // this.config.gizlilik_politikasi = this.getDeger(
          //   "gizlilik_politikasi"
          // );
        }
      } catch (error) {
        console.error("Error loading config:", error);
      }
    },
    async toast(status, message) {
      switch (status) {
        case "success":
          toast.success(message, {
            theme: "auto",
            autoClose: 5000,
            dangerouslyHTMLString: true,
          });
          break;
        case "error":
          toast.error(message, {
            theme: "auto",
            autoClose: 5000,
            dangerouslyHTMLString: true,
          });
          break;
        case "danger":
        case "warn":
        case "warning":
          toast.warning(message, {
            theme: "auto",
            autoClose: 5000,
            dangerouslyHTMLString: true,
          });
          break;
        case "info":
          toast.info(message, {
            theme: "auto",
            autoClose: 5000,
            dangerouslyHTMLString: true,
          });
          break;
        default:
          toast(message, {
            theme: "auto",
            type: "default",
            autoClose: 5000,
            dangerouslyHTMLString: true,
          });
      }
    },
    async listIl() {
      const res = await api.get("il");
      if (res != undefined) {
        this.ilList = res?.data || [];
      }
    },
    async copyHafiza(mytext) {
      try {
        await navigator.clipboard.writeText(mytext);
        alert("Copied");
      } catch ($e) {
        alert("Cannot copy");
      }
    },
    encryptData(data) {
      const tarih = moment().format("YYYYMMDD");
      const encryptionKey = import.meta.env.VITE_APP_secretKey;
      const secretKey = `${encryptionKey}${tarih}`;

      const dataString = JSON.stringify(data);

      // Veriyi şifreleyin
      const encryptedData = CryptoJS.AES.encrypt(
        dataString,
        secretKey
      ).toString();

      return encryptedData;
    },
  },
});
