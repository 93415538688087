import _definePage_default_0 from 'D:/code/tamirci/ui/src/pages/[...error].vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'

export const routes = [
  {
    path: '/',
    name: 'root',
    component: () => import('D:/code/tamirci/ui/src/pages/index.vue'),
    /* no children */
  },
  _mergeRouteRecord(
  {
    path: '/:error(.*)',
    name: '$error',
    component: () => import('D:/code/tamirci/ui/src/pages/[...error].vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  {
    path: '/second-page',
    name: 'second-page',
    component: () => import('D:/code/tamirci/ui/src/pages/second-page.vue'),
    /* no children */
  }
]
