import moment from "moment";

export const filters = {
  formatDateTrTarih(date) {
    if (date && moment(date).isValid()) {
      return moment(date).format("DD.MM.YYYY");
    }
    return null;
  },
  formatDateTrTarihSaat(date) {
    if (date && moment(date).isValid()) {
      return moment(date).format("DD.MM.YYYY HH:mm");
    }
    return null;
  },
  formatDate(date) {
    let parsed = moment(date, "DD.MM.YYYY");
    if (parsed.isValid()) {
      return moment(parsed).format("YYYY-MM-DD");
    } else {
      return null;
    }
  },
  myDate(value) {
    if (value) {
      return moment(String(value)).format("dd/mm/yyyy");
    }
  },
  formatDateAyAdYil(value) {
    moment.locale("tr");
    if (value) {
      return moment(String(value)).format("MMM yyyy");
    }
  },
  phoneMask(value) {
    if (!value) return "";
    value = value.toString();
    return value.replace(
      /^(\d)(\d{3})(\d{3})(\d{2})(\d{2})$/,
      "0($2) $3 $4 $5"
    );
  },
};

// https://momentjs.com/
// Format Dates
// moment().format('MMMM Do YYYY, h:mm:ss a'); // Eylül 10 2022, 2:43:38 ös
// moment().format('dddd');                    // Cumartesi
// moment().format("MMM Do YY");               // Eyl 10 22
// moment().format('YYYY [escaped] YYYY');     // 2022 escaped 2022
// moment().format();                          // 2022-09-10T14:43:38+03:00
// Relative Time
// moment("20111031", "YYYYMMDD").fromNow(); // 11 yıl önce
// moment("20120620", "YYYYMMDD").fromNow(); // 10 yıl önce
// moment().startOf('day').fromNow();        // 15 saat önce
// moment().endOf('day').fromNow();          // 9 saat sonra
// moment().startOf('hour').fromNow();       // 44 dakika önce
// Calendar Time
// moment().subtract(10, 'days').calendar(); // 31.08.2022
// moment().subtract(6, 'days').calendar();  // geçen Pazar saat 14:44
// moment().subtract(3, 'days').calendar();  // geçen Çarşamba saat 14:44
// moment().subtract(1, 'days').calendar();  // dün 14:44
// moment().calendar();                      // bugün saat 14:44
// moment().add(1, 'days').calendar();       // yarın saat 14:44
// moment().add(3, 'days').calendar();       // gelecek Salı saat 14:44
// moment().add(10, 'days').calendar();      // 20.09.2022
// Multiple Locale Support
// moment().format('LT');   // 14:42
// moment().format('LTS');  // 14:42:44
// moment().format('L');    // 10.09.2022
// moment().format('l');    // 10.9.2022
// moment().format('LL');   // 10 Eylül 2022
// moment().format('ll');   // 10 Eyl 2022
// moment().format('LLL');  // 10 Eylül 2022 14:42
// moment().format('lll');  // 10 Eyl 2022 14:42
// moment().format('LLLL'); // Cumartesi, 10 Eylül 2022 14:42
// moment().format('llll'); // Cmt, 10 Eyl 2022 14:42
