export const routesAdmin = [
  {
    path: "/parametre",
    name: "parametre",
    component: () => import("@/views/parametre/index.vue"),
    meta: {
      public: false,
      title: "Parametre",
      yetki: { k: [], a: true },
    },
  },
];
