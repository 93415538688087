import App from "@/App.vue";
import { filters } from "@/aygulyazilim/filters";
import { registerPlugins } from "@core/utils/plugins";
import { registerLicense } from "@syncfusion/ej2-base";
import { createApp } from "vue";
import Vue3Filters from "vue3-filters";

// Styles
import "@core/scss/template/index.scss";
import "@styles/styles.scss";

// Registering Syncfusion license key
registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NDaF5cWWtCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWH5cdnVWQmhfUkdyX0E="
);
// Create vue app
const app = createApp(App);

// import textDirectives from "@/aygulyazilim/directives/text";
// import inputUpper from "@/aygulyazilim/directives/text/inputUpper";
// app.directive("input-upper", inputUpper);
// app.directive("strToUpper", textDirectives.strToUpper);
// app.directive("capitalize", textDirectives.capitalize);
// app.directive("lowerCase", textDirectives.lowerCase);

// Register plugins
registerPlugins(app);

// app.mixin(Vue3Filters.mixin);

app.config.globalProperties.$filters = { ...Vue3Filters.filters, ...filters };

// .env dosyasındaki VITE_API_BASE_URL değişkenini global olarak tanımlıyoruz
app.config.globalProperties.$config = {
  apiBaseUrl: import.meta.env.VITE_API_ENDPOINT.endsWith("/")
    ? import.meta.env.VITE_API_ENDPOINT
    : import.meta.env.VITE_API_ENDPOINT + "/",
};

// Mount vue app
app.mount("#app");
